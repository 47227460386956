import React, { FC, useState } from "react";
import AnimateHeight from "react-animate-height";
import { BadgerButton, ButtonCon, COLORS } from "./Mint";
import { BADGER_TYPE, useAppState } from "./state";
import { ColumnFlex, Port, RowFlex, SubText, Text, TriText } from "./styled";

import { openContractCall } from "@stacks/connect";
import {
  FungibleConditionCode,
  AnchorMode,
  makeStandardSTXPostCondition,
  intCV,
  uintCV,
  PostCondition,
  callReadOnlyFunction,
  makeStandardNonFungiblePostCondition,
  NonFungibleConditionCode,
  createAssetInfo,
} from "@stacks/transactions";

import {
  BURN_ONE,
  BURN_TEN,
  BURN_TWO,
  CONTRACT_ADDRESS,
  CONTRACT_NAME,
  NETWORK,
} from "../const";
import {
  NoBadgers,
  ShowTransactionAddy,
  TileImage,
  TileImageBurned,
  Tiles,
} from "./BurnViews";
import { BadgerFooter } from "./Landing";

const Burn = () => {
  const { badgers, senderAddress, _AttemptedBurn, attemptedBurn, coolBadgers } =
    useAppState();
  const [totalBurn, _TotalBurn] = useState<number>(0);
  const [selectedBadgers, _SelectedBadgers] = useState<BADGER_TYPE[]>([]);
  const [txAddy, _txAddy] = useState<string | undefined>(undefined);
  const [err, _Err] = useState<string | undefined>(undefined);
  const [loading, _Loading] = useState<boolean>(false);
  const handleSelectBurnAmount = (amount: number) => {
    if (amount !== totalBurn) {
      _TotalBurn(amount);
      _SelectedBadgers([]);
    }
  };
  const handleBurn = async () => {
    try {
      if (senderAddress) {
        _Loading(true);
        //const postConditionCode = FungibleConditionCode.GreaterEqual;
        //const
        let postConditionAmount = 0;
        let functionName = "";
        if (totalBurn === 1) {
          functionName = BURN_ONE;
        } else if (totalBurn === 2) {
          functionName = BURN_TWO;
        } else if (totalBurn === 10) {
          functionName = BURN_TEN;
        }

        const postConditionAddress = senderAddress;
        const postConditionCode = NonFungibleConditionCode.DoesNotOwn;
        const assetAddress = "SP38FN88VZ97GWV0E8THXRM6Z5VMFPHFY4J1JEC5S";
        const assetContractName = "btc-badgers";
        const assetName = "btc-badgers-nft";
        const assetNameNew = "btc-badgers-nft-v2";
        //const tokenAssetName = uintCV(selectedBadgers[0].id);
        const nonFungibleAssetInfoOld = createAssetInfo(
          assetAddress,
          assetContractName,
          assetName
        );

        const nonFungibleAssetInfoNew = createAssetInfo(
          CONTRACT_ADDRESS,
          CONTRACT_NAME,
          assetNameNew
        );

        const args = selectedBadgers.map((d, i) => {
          return uintCV(d.id);
        });
        const postConditionsBurn = selectedBadgers.map((d, i) => {
          return makeStandardNonFungiblePostCondition(
            senderAddress,
            postConditionCode,
            nonFungibleAssetInfoOld,
            uintCV(d.id)
          );
        });

        const postConditionsTransfer = selectedBadgers.map((d, i) => {
          return makeStandardNonFungiblePostCondition(
            senderAddress,
            NonFungibleConditionCode.Owns,
            nonFungibleAssetInfoNew,
            uintCV(d.id)
          );
        });

        const PC = [...postConditionsBurn, ...postConditionsTransfer];

        const txOptions = {
          contractAddress: CONTRACT_ADDRESS,
          contractName: CONTRACT_NAME,
          functionName: functionName,
          functionArgs: args,
          senderKey: senderAddress,
          validateWithAbi: true,
          network: NETWORK,
          postConditions: PC,
          anchorMode: AnchorMode.Any,
          onFinish: (data: any) => {
            console.log("we did it", data);

            handleSuccessBurn(data.txId);
          },
          onCancel: () => {
            console.log("err");
            _Loading(false);
          },
        };
        console.log("txOptions", txOptions);
        const transaction = await openContractCall(txOptions);
        console.log("transation", transaction);
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const handleSelectBadger = (badger: BADGER_TYPE) => {
    const selected = selectedBadgers.find((d, i) => {
      return d.id === badger.id;
    });
    if (selected) {
      const removedItem = selectedBadgers.filter((d, i) => {
        return d.id !== badger.id;
      });
      _SelectedBadgers(removedItem);
    } else {
      if (selectedBadgers.length < totalBurn) {
        _SelectedBadgers([...selectedBadgers, badger]);
      } else {
        console.log("Already selected the full amount");
      }
    }
  };

  const handleSuccessBurn = (txId: string) => {
    _txAddy(txId);

    const updatedWith = selectedBadgers.map((d, i) => {
      return {
        ...d,
        txID: txId,
      };
    });

    _AttemptedBurn([...attemptedBurn, ...updatedWith]);
    const strBad = JSON.stringify([...attemptedBurn, ...selectedBadgers]);
    localStorage.setItem("attemptedBurn", strBad);
    _TotalBurn(0);
    _SelectedBadgers([]);
    _Loading(false);
  };

  const fuckyou = () => {
    _txAddy(undefined);
  };
  if (txAddy) {
    return <ShowTransactionAddy txAddy={txAddy} fuckyou={fuckyou} />;
  }

  if (badgers.length === 0 && coolBadgers.length === 0) {
    return <NoBadgers />;
  }

  console.log("badgers", badgers);
  return (
    <>
      <ColumnFlex minHeight="70%" alignItems="center" justifyContent="center">
        {badgers.length !== 0 && (
          <>
            <Port marginBottom="0px">Burn Those Badgers</Port>
            <Text
              style={{
                marginBottom: "6px",
                color: "#ff7c7e",
                fontSize: "20px",
                lineHeight: "44px",
                fontWeight: 400,
                marginTop: "6px",
              }}
            >
              One does not simply walk into Mordor
            </Text>
            <Text
              style={{
                marginBottom: "6px",
                color: "#ff7c7e",
                fontSize: "20px",
                lineHeight: "44px",
                fontWeight: 400,
                marginTop: "6px",
              }}
            >
              They must be chosen to make the journey
            </Text>
            <RowFlex
              width="600px"
              alignItems="center"
              marginTop="20px"
              justifyContent={
                badgers.length < 10 ? "space-around" : "space-between"
              }
            >
              <BadgerButton
                color={COLORS.Primary}
                onClickFunc={() => handleSelectBurnAmount(1)}
                text="Burn 1!"
                selected={totalBurn === 1}
              />
              {badgers.length > 1 && (
                <BadgerButton
                  color={COLORS.Primary}
                  onClickFunc={() => handleSelectBurnAmount(2)}
                  selected={totalBurn === 2}
                  text="Burn 2!"
                />
              )}
              {badgers.length > 9 && (
                <BadgerButton
                  color={COLORS.Primary}
                  onClickFunc={() => handleSelectBurnAmount(10)}
                  selected={totalBurn === 10}
                  text="Burn 10!"
                />
              )}
            </RowFlex>
            <AnimateHeight
              duration={500}
              height={totalBurn > 0 ? "auto" : "0%"}
            >
              <Text color="white" marginTop="20px">
                {totalBurn > 0
                  ? `Select ${totalBurn} Badger${
                      totalBurn > 1 ? "s" : ""
                    } to burn up`
                  : ""}
              </Text>
              <TriText
                color="white"
                textAlign="center"
              >{`Selected ${selectedBadgers.length} of ${totalBurn}`}</TriText>
            </AnimateHeight>
            <AnimateHeight
              duration={500}
              height={
                totalBurn > 0 && totalBurn === selectedBadgers.length
                  ? "auto"
                  : "0%"
              }
            >
              {loading ? (
                <Port
                  textAlign="center"
                  className="loading"
                  marginBottom="20px"
                >
                  Into the volcano these go..
                </Port>
              ) : (
                <ButtonCon
                  className="button-container stx"
                  onClick={() => handleBurn()}
                  style={{
                    display: "inline-block",
                    paddingRight: "3px",
                    justifyContent: "center",
                    paddingBottom: "6px",
                    borderRadius: "100px",
                    backgroundColor: COLORS.Primary,
                    cursor: "pointer",
                    minWidth: "180px",
                    marginTop: "10px",
                  }}
                >
                  <div
                    className="button-2 w-button w--current"
                    style={{
                      padding: "10px 15px",
                      borderRadius: "1000px",
                      margin: "auto",
                      fontFamily: "Poppins, sans-seri",
                      border: 0,
                      color: "white",
                      fontSize: "30px",
                      fontWeight: 700,
                      textAlign: "center",
                    }}
                  >
                    <SubText>Burn Selected Badgers</SubText>
                  </div>
                </ButtonCon>
              )}
            </AnimateHeight>

            <Tiles>
              {badgers.map((d, i) => {
                return (
                  <TileImage
                    handleSelectBadger={handleSelectBadger}
                    badger={d}
                    selectedBadgers={selectedBadgers}
                    key={d.dna}
                  />
                );
              })}
            </Tiles>
          </>
        )}
        {coolBadgers.length !== 0 && (
          <>
            {badgers.length !== 0 && (
              <div
                style={{
                  marginTop: "40px",
                  width: "70%",
                  height: "2px",
                  backgroundColor: "white",
                }}
              />
            )}
            <Port marginBottom="0px">So fresh so clean</Port>
            <Text
              style={{
                color: "#ff7c7e",
                fontSize: "20px",
                lineHeight: "44px",
                fontWeight: 400,
                marginTop: "6px",
              }}
            >
              Ain't nobody dope as you
            </Text>
            <Text
              style={{
                marginBottom: "6px",
                color: "#white",
                fontSize: "20px",
                lineHeight: "44px",
                fontWeight: 400,
              }}
            >
              Burned Badgers
            </Text>
            <Tiles>
              {coolBadgers.map((d, i) => {
                return <TileImageBurned badger={d} key={d.dna} />;
              })}
            </Tiles>
          </>
        )}
      </ColumnFlex>
      <BadgerFooter />
    </>
  );
};

export default Burn;
