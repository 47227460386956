import styled from "styled-components";
import { Button, ColumnFlex, Port, SubText, Text } from "./styled";
import STXLogo from "../assets/STX-Logo.png";
import BTCBadger from "../assets/badgerLogoBTC.png";
import { useConnect } from "@stacks/connect-react";
import { ButtonCon, COLORS } from "./Mint";
import { useAppState } from "./state";

const LogoWrapper = styled.div`
  display: inline-block;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: rgba(37, 46, 70, 0.24);
`;

export const HiroWallet = () => {
  return (
    <>
      <LogoWrapper>
        <img
          style={{
            width: "42px",
            verticalAlign: "midddle",
            maxWidth: "100%",
            display: "inline-block",
          }}
          src={STXLogo}
          alt="banner"
        />
      </LogoWrapper>
    </>
  );
};

const Navigation = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 20px 0px;
  padding-right: 100px;
  padding-left: 100px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #6e97ff;

  height: 10%;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: transparent;
`;

export const NavigationHeader = () => {
  const { doOpenAuth } = useConnect();
  const {
    authenticated,
    _senderAddress,
    _authenticated,
    _badgers,
    _AttemptedBurn,
  } = useAppState();
  const initAuth = () => {
    doOpenAuth();
  };

  const handleLogout = () => {
    localStorage.removeItem("peak");
    localStorage.removeItem("attemptedBurn");
    _senderAddress(undefined);
    _authenticated(false);
    _badgers([]);
    _AttemptedBurn([]);
  };
  const handleViewTx = () => {
    window.open(`https://www.bitcoinbadgers.art/`);
  };
  return (
    <Navigation>
      <Text
        fontFamily="Poppins,sans-serif;"
        color="#ff7c7e"
        fontWeight={700}
        fontSize="20px"
        letterSpacing="1.5px"
        onClick={() => handleViewTx()}
        style={{ cursor: "pointer" }}
      >
        Bitcoin Badgers
      </Text>
      {!authenticated && (
        <ButtonCon
          className="button-container stx"
          onClick={() => initAuth()}
          color={COLORS.four}
          style={{
            display: "inline-block",
            paddingRight: "3px",
            justifyContent: "center",
            paddingBottom: "6px",
            borderRadius: "100px",
            backgroundColor: COLORS.Tertiary,
            cursor: "pointer",
            minWidth: "180px",
          }}
        >
          <div
            className="button-2 w-button w--current"
            style={{
              padding: "10px 15px",
              borderRadius: "1000px",
              margin: "auto",
              fontFamily: "Poppins, sans-seri",
              border: 0,
              color: "white",
              fontSize: "30px",
              fontWeight: 700,
              textAlign: "center",
              backgroundColor: "rgba(6, 7, 19, 0.65)",
            }}
          >
            <SubText fontFamily="Poppins,sans-serif;">Connect Wallet</SubText>
          </div>
        </ButtonCon>
      )}
      {authenticated && (
        <ButtonCon
          className="button-container stx"
          onClick={() => handleLogout()}
          color={COLORS.red}
          style={{
            display: "inline-block",
            paddingRight: "3px",
            justifyContent: "center",
            paddingBottom: "6px",
            borderRadius: "100px",
            backgroundColor: COLORS.Tertiary,
            cursor: "pointer",
            minWidth: "180px",
          }}
        >
          <div
            className="button-2 w-button w--current"
            style={{
              padding: "10px 15px",
              borderRadius: "1000px",
              margin: "auto",
              fontFamily: "Poppins, sans-seri",
              border: 0,
              color: "white",
              fontSize: "30px",
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            <SubText fontFamily="Poppins,sans-serif;">Log Out</SubText>
          </div>
        </ButtonCon>
      )}
    </Navigation>
  );
};

const Foot = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 20%;
`;
export const BadgerFooter = () => {
  return (
    <ColumnFlex
      marginBottom="00px"
      height="20%"
      alignItems="center"
      justifyContent="flex-end"
    >
      <img width={"166px"} src={BTCBadger} />
    </ColumnFlex>
  );
};
