import { AppConfig, UserSession } from "@stacks/connect";
import { useConnect, Connect } from "@stacks/connect-react";
import {
  AccountsApi,
  AddressNftListResponse,
  Configuration,
  Middleware,
  TransactionsApi,
} from "@stacks/blockchain-api-client";

import { ATTEMPTED_BUR_BADGER_TYPE, BADGER_TYPE, useAppState } from "./state";

import Mint, { BadgerButton, COLORS, WhiteListCheck } from "./Mint";
import { ColumnFlex, Port, Text } from "./styled";

import badgerMetaData from "../assets/bitcoin_badgers_metadata.json";
import BTCBadger from "../assets/badgerLogoBTC.png";

import babyBadger from "../assets/babyBadger.png";

import { BadgerFooter, HiroWallet, NavigationHeader } from "./Landing";
import Burn from "./Burn";
import { useEffect, useState } from "react";

const appConfig = new AppConfig(["store_write", "publish_data"]);
const userSession = new UserSession({ appConfig });

const hiroHeaders: HeadersInit = {
  "x-hiro-product": "bitcoin-badger-web",
  "x-hiro-version": "0.01",
};

export function fetchApi(input: RequestInfo, init: RequestInit = {}) {
  const initHeaders = init.headers || {};
  return fetch(input, {
    credentials: "omit",
    ...init,
    headers: { ...initHeaders, ...hiroHeaders },
  });
}

export async function fetchWithTimeout(
  input: RequestInfo,
  init: RequestInit & { timeout?: number } = {}
) {
  const { timeout = 8000, ...options } = init;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetchApi(input, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);

  return response;
}

const DEFAULT_TESTNET_SERVER = "https://stacks-node-api.testnet.stacks.co";
const DEFAULT_MAINNET_SERVER = "https://stacks-node-api.stacks.co";
const BADGER_ADDRESS_V1 =
  "SP38FN88VZ97GWV0E8THXRM6Z5VMFPHFY4J1JEC5S.btc-badgers::btc-badgers-nft";
const BADGER_ADDRESS_V2 =
  "SP27F9EJH20K3GT6GHZG0RD08REZKY2TDMD6D9M2Z.btc-badgers-v2::btc-badgers-nft-v2";
function createConfig(basePath: string, anchored?: boolean) {
  const middleware: Middleware[] = [];

  return new Configuration({
    basePath,
    fetchApi,
    middleware,
  });
}
export const CONFIG_BC_S = createConfig(DEFAULT_MAINNET_SERVER);

const accountsApi = new AccountsApi(CONFIG_BC_S);
export const transactionsAPI = new TransactionsApi(CONFIG_BC_S);

export const ConnectWalletButton = () => {
  const { doOpenAuth } = useConnect();
  const initAuth = () => {
    doOpenAuth();
  };
  return (
    <BadgerButton
      color={COLORS.Primary}
      text=" Connect Wallet"
      onClickFunc={initAuth}
    />
  );
};

const ConnectWallet = () => {
  const {
    authenticated,
    _authenticated,
    _senderAddress,
    _badgers,
    _AttemptedBurn,
    _CoolBadgers,
  } = useAppState();
  const [loading, _Loading] = useState<boolean>(false);

  useEffect(() => {
    const senderAddy = localStorage.getItem("peak");
    //const senderAddy = "SP13M69F9YG8XAQAK3G1MDSHK5BHH8WQD8E9EDGDY";
    if (senderAddy) {
      fetchBadgers(senderAddy);
      _authenticated(true);
      _senderAddress(senderAddy);
    }
    const attemptedBurn = localStorage.getItem("attemptedBurn");
    if (attemptedBurn) {
      try {
        const _ab = JSON.parse(attemptedBurn) as ATTEMPTED_BUR_BADGER_TYPE[];
        _AttemptedBurn(_ab);
      } catch (err) {
        console.log("it did not work ");
      }
    }
  }, []);

  const fetchBadgers = async (senderAddress: string) => {
    try {
      _Loading(true);

      const accountNFts = (await accountsApi.getAccountNft({
        principal: senderAddress,
        offset: 0,
        limit: 50,
      })) as AddressNftListResponse;
      const accountNFt = (await accountsApi.getAccountNft({
        principal: senderAddress,
        offset: 50,
        limit: 50,
      })) as AddressNftListResponse;
      const accountNFtASH = (await accountsApi.getAccountNft({
        principal: senderAddress,
        offset: 100,
        limit: 50,
      })) as AddressNftListResponse;
      const accountNFtASHIH = (await accountsApi.getAccountNft({
        principal: senderAddress,
        offset: 150,
        limit: 50,
      })) as AddressNftListResponse;
      const badgersThings = [
        ...accountNFts.nft_events,
        ...accountNFt.nft_events,
        ...accountNFtASH.nft_events,
        ...accountNFtASHIH.nft_events,
      ];

      const badgers = badgersThings.filter((d, i) => {
        return d.asset_identifier === BADGER_ADDRESS_V1;
      });

      const ownedBadgerInfo: BADGER_TYPE[] = [];

      for (const badger of badgers) {
        const id = badger.value.repr.replace(/\D/g, "");
        const _ID = parseInt(id, 10);

        const badge = badgerMetaData.find((d, i) => {
          return d.name === `bitcoin_badger_${_ID}`;
        });

        if (badge) {
          const _editBadge: BADGER_TYPE = {
            ...badge,
            imageUrl: `https://bitcoinbadgerstest1.mypinata.cloud/ipfs/QmTysxTwP4hxJ5MFe3vc9rWQykjHRsbuzjrnTks9ipgRvJ/${
              badge.name || ""
            }.gif`,
            id: _ID,
          };
          ownedBadgerInfo.push(_editBadge);
        }
      }

      _senderAddress(senderAddress);
      _badgers(ownedBadgerInfo);
      _Loading(false);
    } catch (err) {
      console.log("noooo", err);
      _Loading(false);
    }
  };
  const AUTH_OPTIONS = {
    appDetails: {
      name: "Bitcoin Badgers",
      icon: "https://cdn.discordapp.com/attachments/893876956863549460/914247645160144916/Dracubader.png",
    },
    redirectTo: "/",
    onFinish: async () => {
      try {
        _Loading(true);
        let userData = userSession.loadUserData();

        const senderAddress = userData.profile.stxAddress.mainnet;
        //const senderAddress = "SP23Z3QX3CPAF7ARD2N1YP4BR5ATZW9X2Z6J0740J";
        const accountNFts = (await accountsApi.getAccountNft({
          principal: senderAddress,
          offset: 0,
          limit: 50,
        })) as AddressNftListResponse;
        const accountNFt = (await accountsApi.getAccountNft({
          principal: senderAddress,
          offset: 50,
          limit: 50,
        })) as AddressNftListResponse;
        const accountNFtASH = (await accountsApi.getAccountNft({
          principal: senderAddress,
          offset: 100,
          limit: 50,
        })) as AddressNftListResponse;
        const accountNFtASHIH = (await accountsApi.getAccountNft({
          principal: senderAddress,
          offset: 150,
          limit: 50,
        })) as AddressNftListResponse;
        const badgersThings = [
          ...accountNFts.nft_events,
          ...accountNFt.nft_events,
          ...accountNFtASH.nft_events,
          ...accountNFtASHIH.nft_events,
        ];
        const badgers = badgersThings.filter((d, i) => {
          console.log("d.asset_identifier", d.asset_identifier);
          if (d.asset_identifier === BADGER_ADDRESS_V1) {
            console.log(d);
          }
          return d.asset_identifier === BADGER_ADDRESS_V1;
        });

        const newBadgers = badgersThings.filter((d, i) => {
          return d.asset_identifier === BADGER_ADDRESS_V2;
        });

        const ownedBadgerInfo: BADGER_TYPE[] = [];
        const newOwnedBadgerInfo: ATTEMPTED_BUR_BADGER_TYPE[] = [];

        for (const badger of badgers) {
          const id = badger.value.repr.replace(/\D/g, "");
          const _ID = parseInt(id, 10);
          console.log(_ID);
          const badge = badgerMetaData.find((d, i) => {
            return d.name === `bitcoin_badger_${_ID}`;
          });

          if (badge) {
            const _editBadge: BADGER_TYPE = {
              ...badge,
              imageUrl: `https://bitcoinbadgerstest1.mypinata.cloud/ipfs/QmTysxTwP4hxJ5MFe3vc9rWQykjHRsbuzjrnTks9ipgRvJ/${
                badge.name || ""
              }.gif`,
              id: _ID,
            };
            ownedBadgerInfo.push(_editBadge);
          }
        }
        for (const nBadger of newBadgers) {
          const id = nBadger.value.repr.replace(/\D/g, "");
          const _ID = parseInt(id, 10);
          console.log(_ID);
          const badge = badgerMetaData.find((d, i) => {
            return d.name === `bitcoin_badger_${_ID}`;
          });

          if (badge) {
            const _editNewBadge: ATTEMPTED_BUR_BADGER_TYPE = {
              ...badge,
              imageUrl: `https://bitcoinbadgerstest1.mypinata.cloud/ipfs/QmTysxTwP4hxJ5MFe3vc9rWQykjHRsbuzjrnTks9ipgRvJ/${
                badge.name || ""
              }.gif`,
              id: _ID,
              txID: nBadger.tx_id,
            };
            newOwnedBadgerInfo.push(_editNewBadge);
          }
        }
        const CleanedNewOwnedBadgers = newOwnedBadgerInfo.filter(
          (d, i, self) => {
            return (
              i ===
              self.findIndex((t) => {
                return t.id === d.id;
              })
            );
          }
        );
        const CleanedOldBadgers = ownedBadgerInfo.filter((d, i, self) => {
          return (
            i ===
            self.findIndex((t) => {
              return t.id === d.id;
            })
          );
        });
        _authenticated(true);
        _senderAddress(senderAddress);
        _badgers(CleanedOldBadgers);
        _Loading(false);
        _CoolBadgers(CleanedNewOwnedBadgers);

        localStorage.setItem("peak", senderAddress);
      } catch (err) {
        console.log("noooo", err);
        _Loading(false);
      }
    },
    userSession: userSession,
  };

  if (loading) {
    return (
      <ColumnFlex height="70%" alignItems="center" justifyContent="center">
        <HiroWallet />
        <Port
          textAlign="center"
          color="#ff7c7e"
          className="loading"
          marginBottom="20px"
          style={{ color: "#ff7c7e" }}
        >
          Firing up the volcano..
        </Port>
        <div style={{ marginBottom: "20px" }}>
          <img
            style={{ borderRadius: "50%" }}
            width={"200px"}
            src={BTCBadger}
            alt="babyLoading"
          />
        </div>
      </ColumnFlex>
    );
  }
  return (
    <Connect authOptions={AUTH_OPTIONS}>
      <NavigationHeader />

      {authenticated ? (
        <Burn />
      ) : (
        <>
          <ColumnFlex height="70%" alignItems="center" justifyContent="center">
            <HiroWallet />
            <Port marginBottom="0px">Connect Your Hiro Wallet To Burn</Port>
            <Text
              fontFamily="Poppins, sans-seri"
              style={{
                marginBottom: "40px",
                color: "#ff7c7e",
                fontSize: "20px",
                lineHeight: "44px",
                fontWeight: 400,
                marginTop: "20px",
              }}
            >
              you sexy honey badger, you
            </Text>
            <ConnectWalletButton />
          </ColumnFlex>
          <BadgerFooter />
        </>
      )}
    </Connect>
  );
};
export default ConnectWallet;
