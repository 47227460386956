import React, { createContext, useContext, useState } from "react";

export type BADGER_TYPE = {
  name: string;
  description: string;
  image: string;
  dna: string;
  edition: string;
  date: number;
  attributes: any;
  compiler: string;
  imageUrl: string;
  id: number;
};
export type ATTEMPTED_BUR_BADGER_TYPE = {
  name: string;
  description: string;
  image: string;
  dna: string;
  edition: string;
  date: number;
  attributes: any;
  compiler: string;
  imageUrl: string;
  id: number;
  txID: string;
};
type INIT_STATE_TYPE = {
  isWhiteList: boolean;
  senderAddress?: string;
  authenticated: boolean;
  badgers: BADGER_TYPE[];
  attemptedBurn: ATTEMPTED_BUR_BADGER_TYPE[];
  coolBadgers: ATTEMPTED_BUR_BADGER_TYPE[];
  _isWhiteList: (arg0: boolean) => void;
  _senderAddress: (address: string | undefined) => void;
  _authenticated: (authenticated: boolean) => void;
  _badgers: (badgers: BADGER_TYPE[]) => void;
  _AttemptedBurn: (badgers: ATTEMPTED_BUR_BADGER_TYPE[]) => void;
  _CoolBadgers: (badgers: ATTEMPTED_BUR_BADGER_TYPE[]) => void;
};

const INIT_STATE = {
  isWhiteList: false,
  senderAddress: undefined,
  authenticated: false,
  badgers: [],
  attemptedBurn: [],
  coolBadgers: [],
};

export const StateContext = createContext<INIT_STATE_TYPE>(null!);

const StateLogic = (props: React.PropsWithChildren<{}>) => {
  const [isWhiteList, _isWhiteList] = useState<boolean>(INIT_STATE.isWhiteList);
  const [senderAddress, _senderAddress] = useState<string | undefined>(
    INIT_STATE.senderAddress
  );
  const [authenticated, _authenticated] = useState<boolean>(false);
  const [badgers, _badgers] = useState<BADGER_TYPE[]>([]);
  const [attemptedBurn, _AttemptedBurn] = useState<ATTEMPTED_BUR_BADGER_TYPE[]>(
    []
  );
  const [coolBadgers, _CoolBadgers] = useState<ATTEMPTED_BUR_BADGER_TYPE[]>([]);

  let contextValue = {
    isWhiteList,
    _isWhiteList,
    senderAddress,
    _senderAddress,
    authenticated,
    _authenticated,
    badgers,
    _badgers,
    attemptedBurn,
    _AttemptedBurn,
    coolBadgers,
    _CoolBadgers,
  };

  return (
    <StateContext.Provider value={{ ...contextValue }}>
      {props.children}
    </StateContext.Provider>
  );
};

export default StateLogic;

export const useAppState = () => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }
  return context;
};
