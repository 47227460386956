import {
  GetTransactionByIdRequest,
  TransactionsApi,
} from "@stacks/blockchain-api-client";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { CONFIG_BC_S, transactionsAPI } from "./ConnectWallet";
import { BadgerFooter } from "./Landing";
import { BadgerButton, ButtonCon, COLORS } from "./Mint";
import { ATTEMPTED_BUR_BADGER_TYPE, BADGER_TYPE, useAppState } from "./state";
import { ColumnFlex, Port, Text } from "./styled";

import BTCBadgerSaint from "../assets/Saint_for_website.png";
import { colorStyle } from "styled-system";

type BULLSHIT = {
  txAddy: string | undefined;
  fuckyou: () => void;
};
export const ShowTransactionAddy = (props: BULLSHIT) => {
  const { txAddy, fuckyou } = props;
  const handleViewTx = () => {
    if (txAddy) {
      window.open(`https://explorer.stacks.co/txid/${txAddy}?chain=mainnet`);
    }
  };
  return (
    <>
      <ColumnFlex
        height="80%"
        style={{ backgroundColor: "#EAF1FF" }}
        alignItems="center"
        justifyContent="center"
      >
        <Text color="#000000" margin="0px">
          They'll live on in our hearts and rise from the ashes anew
        </Text>
        <div style={{ marginBottom: "20px" }}>
          <img width={"400px"} src={BTCBadgerSaint} />
        </div>

        <ButtonCon
          className="button-container stx"
          onClick={() => handleViewTx()}
          color={COLORS.Primary}
          style={{
            display: "inline-block",
            paddingRight: "3px",
            justifyContent: "center",
            paddingBottom: "6px",
            borderRadius: "100px",
            backgroundColor: COLORS.four,
            cursor: "pointer",
            minWidth: "180px",
          }}
        >
          <div
            className="button-2 w-button w--current"
            style={{
              padding: "20px 30px",
              borderRadius: "1000px",
              margin: "auto",
              fontFamily: "Poppins, sans-seri",
              border: 0,
              color: "white",
              fontSize: "30px",
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            <Text>View transaction in STX Explorer</Text>
          </div>
        </ButtonCon>
        <Text
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => fuckyou()}
          margin="16px"
        >
          Burn More Badgers!
        </Text>
      </ColumnFlex>
      <BadgerFooter />
    </>
  );
};

export const NoBadgers = () => {
  return (
    <ColumnFlex height="70%" alignItems="center" justifyContent="center">
      <Port marginBottom="0px">You Don't own any Badgers to burn</Port>
      <Text
        color="white"
        style={{
          marginBottom: "40px",

          fontSize: "20px",
          lineHeight: "44px",
          fontWeight: 400,
          marginTop: "20px",
        }}
      >
        we still love you!
      </Text>
    </ColumnFlex>
  );
};

export const Tiles = styled.div`
  padding: 1vh 16vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  position: relative;
`;

export const Tile = styled.div`
  margin: 15px;
  cursor: pointer;
  overflow: hidden;
  width: 18vw;
  height: 18vw;
  animate: all ease 0.2s;
  padding: 8px;
  position: relative;

  img {
    width: 100%;
    transition: transform 500ms ease;
    &:hover {
      transform: scale(1.1);
    }
  }
`;

type TileImageProps = {
  badger: BADGER_TYPE;
  selectedBadgers: BADGER_TYPE[];
  handleSelectBadger: (badger: BADGER_TYPE) => void;
};
export const TileImage = (props: TileImageProps) => {
  const { attemptedBurn } = useAppState();
  const [burnStatus, _BurnStatus] = useState<boolean>(false);
  const selected = props.selectedBadgers.find((d, i) => {
    return d.id === props.badger.id;
  });
  const attemptedToBurn = attemptedBurn.find((d, i) => {
    return d.id === props.badger.id;
  });

  const handleClick = () => {
    if (attemptedToBurn) {
      console.log("should not try to reburn");
      window.open(
        `https://explorer.stacks.co/txid/${attemptedToBurn.txID}?chain=mainnet`
      );
    } else {
      props.handleSelectBadger(props.badger);
    }
  };
  useEffect(() => {
    const atb = attemptedBurn.find((d, i) => {
      return d.id === props.badger.id;
    });

    if (atb) {
      fetchTx(atb.txID);
    }
  }, [attemptedBurn.length]);

  const fetchTx = async (txId: string) => {
    try {
      const txStatus = (await transactionsAPI.getTransactionById({
        txId: txId,
      })) as any;

      console.log("txStatus", txStatus);
      if (txStatus && txStatus.tx_status && txStatus.tx_status === "success") {
        _BurnStatus(true);
      }
    } catch (err) {}
  };
  return (
    <Tile
      onClick={() => handleClick()}
      style={{
        border: selected ? `solid 8px ${COLORS.Primary}` : `solid 8px #960002`,
      }}
    >
      <img
        style={{
          filter: attemptedToBurn ? "brightness(50%)" : "brightness(100%)",
        }}
        src={props.badger.imageUrl}
        alt={props.badger.name}
      />
      {attemptedToBurn && (
        <Text
          color="white"
          fontWeight="600"
          className={burnStatus ? "loading" : ""}
          style={{
            zIndex: 200,
            position: "absolute",
            bottom: "8px",
            left: "16px",
            textDecoration: "underline",
          }}
        >
          {!burnStatus ? "Confirming crispiness" : "Success"}
        </Text>
      )}
    </Tile>
  );
};

type TileImagePropsBurned = {
  badger: ATTEMPTED_BUR_BADGER_TYPE;
};
export const TileImageBurned = (props: TileImagePropsBurned) => {
  const { attemptedBurn } = useAppState();
  const [burnStatus, _BurnStatus] = useState<boolean>(false);

  const handleClick = () => {
    window.open(
      `https://explorer.stacks.co/txid/${props.badger.txID}?chain=mainnet`
    );
  };

  return (
    <Tile
      onClick={() => handleClick()}
      style={{
        border: `solid 8px #960002`,
      }}
    >
      <img
        style={{
          filter: "brightness(100%)",
        }}
        src={props.badger.imageUrl}
        alt={props.badger.name}
      />

      <Text
        color="white"
        fontWeight="600"
        className={burnStatus ? "loading" : ""}
        style={{
          zIndex: 200,
          position: "absolute",
          bottom: "8px",
          left: "16px",
          color: COLORS.red,
          textDecoration: "underline",
        }}
      >
        So crispy
      </Text>
    </Tile>
  );
};
