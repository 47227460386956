import { useAppState } from "./state";
import { ColumnFlex, RowFlex, Text } from "./styled";
import styled from "styled-components";

import { openContractCall } from "@stacks/connect";
import {
  FungibleConditionCode,
  AnchorMode,
  makeStandardSTXPostCondition,
  falseCV,
  PostCondition,
} from "@stacks/transactions";

import { CONTRACT_ADDRESS, CONTRACT_NAME, NETWORK } from "../const";
import { useState } from "react";

interface BadgerButtonProps {
  text: string;
  onClickFunc: (whatever?: any) => void;
  color: string;
  selected?: boolean;
}

export const COLORS = {
  Background: "#6e97ff",
  Primary: "#060713",
  Secondary: "#ffffff",
  Tertiary: "#252e46",
  four: "#2ce6ff",
  red: "#ff7c7e",
};

enum MINT_FUNCS {
  CLAIM_FUNC_NAME = "claim",
  CLAIM_TWO_FUNC_NAME = "claim-two",
  CLAIM_FOUR_FUNC_NAME = "claim-four",
}
export const ButtonCon = styled.div`
  cursor: pointer;
  transition: all ease 0.3s;

  .button-2:hover {
    background-color: rgba(6, 7, 19, 0.65);
  }
  .button-2 {
    background-color: ${({ color }) => color};
  }
`;

export const BadgerButton = ({
  text,
  onClickFunc,
  color,
  selected,
}: BadgerButtonProps) => {
  return selected ? (
    <ButtonCon
      className="button-container stx"
      color={color}
      style={{
        display: "inline-block",
        paddingRight: "3px",
        justifyContent: "center",
        paddingBottom: "6px",
        borderRadius: "100px",
        backgroundColor: COLORS.red,
        cursor: "pointer",
        minWidth: "180px",
      }}
    >
      <div
        onClick={() => onClickFunc()}
        className="button-2 w-button w--current"
        style={{
          padding: "20px 30px",
          borderRadius: "1000px",
          margin: "auto",
          fontFamily: "Poppins, sans-seri",
          border: 0,
          color: "white",
          fontSize: "30px",
          fontWeight: 700,
          textAlign: "center",
          backgroundColor: "rgba(6, 7, 19, 0.65)",
        }}
      >
        <Text>{text}</Text>
      </div>
    </ButtonCon>
  ) : (
    <ButtonCon
      className="button-container stx"
      onClick={() => onClickFunc()}
      color={color}
      style={{
        display: "inline-block",
        paddingRight: "3px",
        justifyContent: "center",
        paddingBottom: "6px",
        borderRadius: "100px",
        backgroundColor: COLORS.red,
        cursor: "pointer",
        minWidth: "180px",
      }}
    >
      <div
        className="button-2 w-button w--current"
        style={{
          padding: "20px 30px",
          borderRadius: "1000px",
          margin: "auto",
          fontFamily: "Poppins, sans-seri",
          border: 0,
          color: "white",
          fontSize: "30px",
          fontWeight: 700,
          textAlign: "center",
        }}
      >
        <Text>{text}</Text>
      </div>
    </ButtonCon>
  );
};

interface IForget {
  children: React.ReactNode;
}
export const WhiteListCheck = ({ children }: IForget) => {
  const { isWhiteList } = useAppState();
  return <div> {isWhiteList ? children : "No White list for you"} </div>;
};

const Mint = () => {
  const [txAddy, _txAddy] = useState<string | undefined>(undefined);

  const { senderAddress } = useAppState();

  const handleMint = async (type: MINT_FUNCS) => {
    try {
      if (senderAddress) {
        const postConditionCode = FungibleConditionCode.GreaterEqual;
        //const
        let postConditionAmount = 69000000n;

        if (type === MINT_FUNCS.CLAIM_FOUR_FUNC_NAME) {
          postConditionAmount = 276000000n;
        } else if (type === MINT_FUNCS.CLAIM_TWO_FUNC_NAME) {
          postConditionAmount = 138000000n;
        } else if (type === MINT_FUNCS.CLAIM_FUNC_NAME) {
          postConditionAmount = 69000000n;
        }
        const postConditions: PostCondition[] = [
          makeStandardSTXPostCondition(
            senderAddress,
            postConditionCode,
            postConditionAmount
          ),
        ];

        const txOptions = {
          contractAddress: CONTRACT_ADDRESS,
          contractName: CONTRACT_NAME,
          functionName: type,
          functionArgs: [falseCV()],
          senderKey: senderAddress,
          validateWithAbi: true,
          network: NETWORK,
          postConditions: postConditions,
          anchorMode: AnchorMode.Any,
          onFinish: (data: any) => {
            console.log("we did it", data);
            _txAddy(data.txId);
          },
          onCancel: () => {
            console.log("err");
          },
        };

        console.log("txtOption", txOptions);
        const transaction = await openContractCall(txOptions);
        console.log("transation", transaction);
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const _DUMB = (type: MINT_FUNCS) => {
    handleMint(type);
  };

  const handleViewTx = () => {
    if (txAddy) {
      window.open(`https://explorer.stacks.co/txid/0x${txAddy}?chain=mainnet`);
    }
  };
  return (
    <RowFlex width="600px" alignItems="center" justifyContent="space-between">
      {txAddy ? (
        <ColumnFlex alignItems="center">
          <BadgerButton
            color={COLORS.Primary}
            onClickFunc={() => handleViewTx()}
            text="Congrats! View Your Tx!"
          />
          <Text
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => _txAddy(undefined)}
            margin="16px"
          >
            Mint More Badgers!
          </Text>
        </ColumnFlex>
      ) : (
        <>
          <BadgerButton
            color={COLORS.Primary}
            onClickFunc={() => _DUMB(MINT_FUNCS.CLAIM_FUNC_NAME)}
            text="Mint 1!"
          />
          <BadgerButton
            color={COLORS.Primary}
            onClickFunc={() => _DUMB(MINT_FUNCS.CLAIM_TWO_FUNC_NAME)}
            text="Mint 2!"
          />
          <BadgerButton
            color={COLORS.Primary}
            onClickFunc={() => _DUMB(MINT_FUNCS.CLAIM_FOUR_FUNC_NAME)}
            text="Mint 4!"
          />
        </>
      )}
    </RowFlex>
  );
};

export default Mint;
